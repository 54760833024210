import React from "react";

import '../assets/css/register.css';

import db from "../data/firestore";

import { Container, Row, Col } from "react-bootstrap";

class Register extends React.Component {
  render() {
  var registerCancel = function (e) {
  window.location.href="/";
  }
    var registerSubmit = function (e) {
        e.preventDefault();
        var name = document.getElementById("name").value;
        var email = document.getElementById("email").value;
        var phone = document.getElementById("phone").value;
        var qualification = document.getElementById("qualification").value;
        var experience = document.getElementById("experience").value;
        var message = document.getElementById("message").value;
        var registeredAt = new Date();

        if(qualification === "") {
          alert("Please select the Qualification");
          return false;
      }

        db.collection("registration").add({
          name: name,
          email: email,
          phone: phone,
          qualification: qualification,
          experience: experience,
          message: message,
          registeredAt: registeredAt
      })
      .then((docRef) => {      
        alert('Thanks for registering, we will connect with you shortly.');
        document.getElementById("registrationForm").reset();
      })
      .catch((error) => {
          console.error("Error adding document: ", error);
      });
    }

    document.title = "Register for Skill High | Learn Today 2 Lead Tomorrow";
    document.body.style.background = "#000000 !important";

    return (
      
      <div className="registration">
        <Container fluid>
          <Row>
          <Col sm={12}>
            <div className="registration-title">
              <div className="registration-title-message">
                Register into SkillHigh Technology Training Platform | Transform from Beginner to an Expert Coder.
              </div>
            </div>
          </Col>
          </Row>
          <Row>
          <form id="registrationForm" onSubmit={registerSubmit}>
      <div className="registrationForm-block">
      <Col sm={12}>
        <input type="text" id="name" placeholder="Name **" required  />
      </Col>
      <Col sm={12}>
      <input type="email" id="email" placeholder="Email Address **" required  />
      </Col>
      <Col sm={12}>
      <input type="tel" id="phone" placeholder="Phone Number **" required />
      </Col>
      <Col sm={12}>
      <select name="qualification" id="qualification">
        <option value="">Qualification **</option>
        <option value="4">Below 5th</option>
        <option value="9">Below 10th</option>
        <option value="sslc">SSLC</option>
        <option value="hsc">Higher Secondary</option>
        <option value="undergraduate">Under Graduate</option>
        <option value="postgraduate">Post Graduate</option>
      </select>
      </Col>
      <Col sm={12}>
        <input type="number" id="experience" placeholder="Experience"   />
      </Col>
      <Col sm={12}>
      <textarea id="message" placeholder="Why this Course ? **" required  />
      </Col>
      <Col sm={12}>
        <div className="registration-block">
                <button className="registration-submit btn btn-warning">Submit</button>
                <div onClick={registerCancel} className="text-center registration-cancel">I don't want to register</div>
                </div>
      </Col>
      </div>
      </form>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Register;
