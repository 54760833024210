import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import Header from "../layout/header"
import Footer from "../layout/footer"

import "../assets/css/home.css";
import dp from "../assets/images/dp.jpg";
import skill_high from "../assets/images/skill-high.png";

class Home extends React.Component {
  render() {
    return (
      <div className="home">
      <Header />
        <Container fluid>
          <Row>
            <Col sm={12} className="core-body">
              <Row className="section init">
                <Col sm={6} className="block">
                  <img src={skill_high} alt="Shill High" id="skill_high" />
                </Col>
                <Col sm={6} className="block">
                  <div className="question">What is Skill High Program ?</div>
                  <div className="answer">A Great initiative to learn one or a set of technologies in a comprehensive way. <br /> An intenstion of integrating a group of technology skills and acquiring knowledge to become a master professional in the current Tech World.</div>
                  <div className="question">Why should you enroll ?</div>
                  <div className="answer">A diverse platform which countless options of choosing your own technologies to be learnt in the way you need. <br /> A great advantage of getting placed immediatley after effective learning and understanding technologies.</div>
                  <div className="question">Who should join this program ?</div>
                  <div className="answer"><b>Skill High</b>  is mainly concentrated and designed for Beginners who are looking for carrier upgrade to reach MAANG or equaivalent companies.
                  <ul>
                    <li>Technology Enthusiast</li>
                    <li>Freshers / Begineers</li>
                    <li>Professionals looking to move from Service to Product Companies</li>
                    <li>Professionals planning to move from SDE1 to SDE2 or SDE3</li>
                  </ul>
                  </div>
                </Col>
              </Row>
              <Row className="course-block">
                <Col sm={12}>
                  <Row className="head">
                    <Col sm={6}>
                      <div className="title">
                        Web Designing ( HTML, CSS, JavaScript )
                      </div>
                    </Col>
                    <Col sm={6} className="text-sm-end">
                      <div className="duration">Duration : 4 Weeks</div>
                    </Col>
                  </Row>
                  <Row className="description">
                    <Col sm={4}>
                    <ul>
                      <li>Introduction to Web Technologies</li>
                      <li>How does the browser works ?</li>
                      <li>Tags of HTML</li>
                      <li>Web Hosting</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>How to access CDN ?</li>
                      <li>JQuery</li>
                      <li>Alignments</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>CSS keywords</li>
                      <li>Bootstrap</li>
                      <li>Domains</li>
                    </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="course-block">
                <Col sm={12}>
              <Row className="head">
                <Col sm={6}>
                  <div className="title">Database (SQL, NoSQL)</div>
                </Col>
                <Col sm={6} className="text-sm-end">
                  <div className="duration">Duration : 4 Weeks</div>
                </Col>
              </Row>
                  <Row className="description">
                    <Col sm={4}>
                    <ul>
                      <li>What is a RDBMS ?</li>
                      <li>What is NoSQL ?</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>Tables, Documents, Collections</li>
                      <li>Index, Primary Key, Foregin Key</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>Store Procedures, Functions, Views</li>
                      <li>SQL Query, Server</li>
                    </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="course-block">
                <Col sm={12}>
              <Row className="head">
                <Col sm={6}>
                  <div className="title">
                    Programming Language (Java)
                  </div>
                </Col>
                <Col sm={6} className="text-sm-end">
                  <div className="duration">Duration : 6 Weeks</div>
                </Col>
              </Row>
                  <Row className="description">
                    <Col sm={4}>
                    <ul>
                      <li>Fundemendals of Programming Language</li>
                      <li>Functional vs Structural Language</li>
                      <li>Class / Object</li>
                      <li>Packages (Java)</li>
                      <li>Data Types</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>Keywords (static, final, this, super)</li>
                      <li>Overloading, Overriding</li>
                      <li>Exceptional Handling</li>
                      <li>Maven / Pip</li>
                      <li>Interface</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>OOPS</li>
                      <li>Collections</li>
                      <li>Variables</li>
                      <li>Functions / Methods</li>
                      <li>Constructor</li>
                    </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="course-block">
                <Col sm={12}>
              <Row className="head">
                <Col sm={6}>
                  <div className="title">
                    Data Structures and Algorithm (Techniques)
                  </div>
                </Col>
                <Col sm={6} className="text-sm-end">
                  <div className="duration">Duration : 10 Weeks</div>
                </Col>
              </Row>
                  <Row className="description">
                    <Col sm={4}>
                    <ul>
                      <li>Introduction to Problem Solving</li>
                      <li>Time and Space Complexity</li>
                      <li>Prefix Sum</li>
                      <li>Carry Forward</li>
                      <li>Sliding Window</li>
                      <li>Contribution Technique</li>
                      <li>Tow Pointers</li>
                      <li>Kadane's Algorithm</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>2D Matrices</li>
                      <li>Recursion</li>
                      <li>Bubble Sort</li>
                      <li>Insertion Sort</li>
                      <li>Merge Sort</li>
                      <li>Selection Sort</li>
                      <li>Quick Sort</li>
                      <li>Radix Sort</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>Linear Search</li>
                      <li>Binary Search</li>
                      <li>BFS & DFS</li>
                      <li>In-Order Traversal</li>
                      <li>Pre-Order Traversal</li>
                      <li>Post-Order Traversal</li>
                      <li>Dynamic Programming</li>
                      <li>Bit Manipulation</li>
                    </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="course-block">
                <Col sm={12}>
              <Row className="head">
                <Col sm={6}>
                  <div className="title">
                    Web Development (Framework: Servlet, SpringBoot)
                  </div>
                </Col>
                <Col sm={6} className="text-sm-end">
                  <div className="duration">Duration : 4 Weeks</div>
                </Col>
              </Row>
                  <Row className="description">
                    <Col sm={4}>
                    <ul>
                      <li>Java Database Connectivity (JDBC)</li>
                      <li>Extensible Markup Language (XML), JSON</li>
                      <li>Java Servlets</li>
                      <li>Java Server Pages (JSP)</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>Java Beans</li>
                      <li>JSP Expression Language (EL)</li>
                      <li>JSP Standard Tag Library (JSTL)</li>
                      <li>Filters in Web Application</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>RESTful</li>
                      <li>Maven</li>
                      <li>Spring Boot</li>
                      <li>Postman</li>
                    </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="course-block">
                <Col sm={12}>
              <Row className="head">
                <Col sm={6}>
                  <div className="title">
                    System Design (WhatsApp, Instagram, Telegram)
                  </div>
                </Col>
                <Col sm={6} className="text-sm-end">
                  <div className="duration">Duration : 4 Weeks</div>
                </Col>
              </Row>
                  <Row className="description">
                    <Col sm={4}>
                    <ul>
                      <li>Introduction to Cloud</li>
                      <li>Design Fundamentals</li>
                      <li>Client Server Protocol</li>
                      <li>Network</li>
                      <li>Storage</li>
                      <li>Latency and Throughput</li>
                      <li>Availabilities</li>
                      <li>Caching</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>Proxies</li>
                      <li>Load Balancers</li>
                      <li>Hashing</li>
                      <li>Relational Database</li>
                      <li>Key Value Pair</li>
                      <li>Specialized Storage</li>
                      <li>Replicas and Sharding</li>
                      <li>Leader Election</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>Peer to Peer Networks</li>
                      <li>Polling and Streaming</li>
                      <li>Configuration</li>
                      <li>Logging and Monitoring</li>
                      <li>Publish and Subscribe Pattern</li>
                      <li>WhatsApp</li>
                      <li>Instagram</li>
                      <li>Telegram</li>
                    </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="course-block">
                <Col sm={12}>
              <Row className="head">
                <Col sm={6}>
                  <div className="title">Low Level Design (SOLID)</div>
                </Col>
                <Col sm={6} className="text-sm-end">
                  <div className="duration">Duration : 4 Weeks</div>
                </Col>
              </Row>
                  <Row className="description">
                    <Col sm={4}>
                    <ul>
                      <li>What is LLD ?</li>
                      <li>What is Design Pattern ?</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>API Designing</li>
                      <li>DRY Principle</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>SOLID Principle</li>
                      <li>Comparison</li>
                    </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="course-block">
                <Col sm={12}>
              <Row className="head">
                <Col sm={6}>
                  <div className="title">
                    Machine Learning (Data Pre-Processing, Data Visualisation)
                  </div>
                </Col>
                <Col sm={6} className="text-sm-end">
                  <div className="duration">Duration : 4 Weeks</div>
                </Col>
              </Row>
                  <Row className="description">
                    <Col sm={4}>
                    <ul>
                      <li>What is Machine Learning ?</li>
                      <li>CRISP-DM / CRISP-MLQ</li>
                      <li>Maths Foundation</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>Data Collection</li>
                      <li>Data Cleansing</li>
                      <li>Data Pre-Processing</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>Single / Multi Linear Regression</li>
                      <li>K Nearest Neighbour</li>
                      <li>Recommendation Engine</li>
                    </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="course-block">
                <Col sm={12}>
              <Row className="head">
                <Col sm={6}>
                  <div className="title">
                    Artificial Intelligence (Neural Network)
                  </div>
                </Col>
                <Col sm={6} className="text-sm-end">
                  <div className="duration">Duration : 4 Weeks</div>
                </Col>
              </Row>
                  <Row className="description">
                    <Col sm={4}>
                    <ul>
                      <li>Deep Learning</li>
                      <li>ANN</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>CNN</li>
                      <li>RNN</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>SVM</li>
                      <li>Computer Vision</li>
                    </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="course-block">
                <Col sm={12}>
              <Row className="head">
                <Col sm={6}>
                  <div className="title">
                    Good Coding Practise (Naming, Testing, Design Thinking)
                  </div>
                </Col>
                <Col sm={6} className="text-sm-end">
                  <div className="duration">Duration : 4 Weeks</div>
                </Col>
              </Row>
                  <Row className="description">
                    <Col sm={4}>
                    <ul>
                      <li>Naming Cases (CAML, Pascal, Snake)</li>
                      <li>Right way to provide naming</li>
                      <li>How to search for code ?</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>Why should you do unit test ?</li>
                      <li>Advantages of adding comments</li>
                      <li>How to use Stack Overflow ?</li>
                    </ul>
                    </Col>
                    <Col sm={4}>
                    <ul>
                      <li>How to break a requirement</li>
                      <li>Conver requirements to design</li>
                      <li>How to use ChatGPT for code needs ?</li>
                    </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr></hr>
              <Row>
              <Col sm={6}>
              <Row>
                <Col sm={12} className="section trainer">
                  <u>Trainer's Profile :</u>
                </Col>
              </Row>
              <Row>
                <Col sm={2} className="section trainer_dp">
                  <img src={dp} alt="dp" />
                </Col>
                <Col sm={8} className="section points d-flex align-items-center">
                <ul>
                      <li>Worked on various technologies and domains.</li>
                      <li>Strong Knowledge on System Design using Cloud.</li>
                      <li><a target="_blank" rel="noreferrer" href="https://rskcse1.com">Click here to know more ...</a></li>
                    </ul>
                </Col>
              </Row>
              </Col>
{/*               <Col sm={6}> */}
{/*                             <Row> */}
{/*                               <Col sm={12} className="section trainer"> */}
{/*                                 <u>Our Pack:</u> */}
{/*                               </Col> */}
{/*                             </Row> */}
{/*                             <Row> */}
{/*                               <Col sm={12} className="section points d-flex align-items-center"> */}
{/*                               <ul> */}
{/*                                     <li><a href="/pack">Click here to see our pack who are actively learning...</a></li> */}
{/*                                   </ul> */}
{/*                               </Col> */}
{/*                             </Row> */}
{/*                             </Col> */}
              </Row>
              <div className="blankCover"></div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default Home;
