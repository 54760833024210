import React from "react";

import Header from "../layout/header"
import Footer from "../layout/footer"

import { Container, Row, Col } from "react-bootstrap";

import db from '../data/firestore';

import "../assets/css/wolf.css";

class Wolf extends React.Component {
constructor(props) {
    super(props)
    this.state = {
      name: "Name",
      dp: "No URL",
      course: []
    }
  }
  render() {
  const { name, dp, course} = this.state;
  document.title = name + " | SkillHigh"
  let wolf = this.props.match.params.wolf;
  db.collection("pack").get(wolf).then((docObj) => {
        if (docObj.size > 0) {
          if (name !== docObj.docs[0].data().name) {
            this.setState({
            name: docObj.docs[0].data().name,
            dp: docObj.docs[0].data().dp,
            course: docObj.docs[0].data().course
            });
            console.log("obj", docObj.docs[0].data());
          }
        }
      }).catch((error) => {
        console.log(error);
      })
    return (
      <div className="wolf">
      <Header />
        <Container fluid>
          <Row>
            <Col sm={12} className="core-body">
                <div className='dp'><img src={dp} width="100%" alt={name}></img></div>
                <div className='name'>{name}</div>
                <div className='active'>Active Courses</div>
                {
                  course.map((item) => {
                    return item.isActive && <div className="course">{item.name} :: {item.batch}
                    <div className="assignments">
                    <div className="assignment_heading">Assignments</div>
                    {item.assignment !== undefined && item.assignment.map((aItem) => {
                    return <div className="assignment"><span className="assignment_name">{aItem.name}</span>
                    <span className="assignment_description">{aItem.description}</span>
                    <span className="assignment_tech">{aItem.tech}</span>
                    <span className="assignment_score">{aItem.score}</span>
                    {
                    aItem.url !== undefined && <span>{aItem.url}</span>
                    }
                    </div>
                    })
                    }
                    {
                    item.assignment === undefined && <div>No Assignments Found</div>
                    }
                    </div>
                    <div className="projects">
                    <div className="project_heading">Projects</div>
                    {item.project !== undefined && item.project.map((aItem) => {
                    return <div className="project"><span className="project_name">{aItem.name}</span>
                    <span className="project_description">{aItem.description}</span>
                    <span className="project_tech">{aItem.tech}</span>
                    <span className="project_score">{aItem.score}</span></div>
                    })
                    }
                    {
                    item.project === undefined && <div>No Projects Found</div>
                    }
                    </div>
                    </div>
                  })
                }
                <div className='completed'>Completed Courses</div>
                {
                  course.map((item) => {
                    return !item.isActive && <div className="course">{item.name} :: {item.batch}
                    <div className="assignments">
                    <div className="assignment_heading">Assignments</div>
                    {item.assignment !== undefined && item.assignment.map((aItem) => {
                    return <div className="assignment"><span className="assignment_name">{aItem.name}</span>
                    <span className="assignment_description">{aItem.description}</span>
                    <span className="assignment_tech">{aItem.tech}</span>
                    <span className="assignment_score">{aItem.score}</span>
                    {
                    aItem.url !== undefined && <span>{aItem.url}</span>
                    }
                    </div>
                    })
                    }
                    {
                    item.assignment === undefined && <div>No Assignments Found</div>
                    }
                    </div>
                    <div className="projects">
                    <div className="project_heading">Projects</div>
                    {item.project !== undefined && item.project.map((aItem) => {
                    return <div className="project"><span className="project_name">{aItem.name}</span>
                    <span className="project_description">{aItem.description}</span>
                    <span className="project_tech">{aItem.tech}</span>
                    <span className="project_score">{aItem.score}</span></div>
                    })
                    }
                    {
                    item.project === undefined && <div>No Projects Found</div>
                    }
                    </div>
                    </div>
                  })
                }
            <div className="blankCover"></div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default Wolf;