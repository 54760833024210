import React from "react";

import "../assets/css/layout/header.css";

import { Container, Row, Col } from "react-bootstrap";

import logo from "../assets/images/logo.png";

class Header extends React.Component {
  render() {
    return (
      <div className="header">
        <Container fluid>
          <Row>
            <Col sm={12}>
              <div className="head">
                <Row className="title">
                  <Col
                    sm={6}
                    className="text-center text-sm-start d-none d-sm-block"
                  >
                    <img src={logo} alt="logo" id="logo" />
                    <span className="name">SkillHigh Technology Training</span>
                  </Col>
                  <Col
                    sm={1}
                    className="text-center text-sm-start d-block d-sm-none"
                  >
                    <img src={logo} alt="logo" id="logo" />
                  </Col>
                  <Col
                    sm={1}
                    className="text-center text-sm-start d-block d-xs-none d-sm-none"
                  >
                    <span className="name">SkillHigh Technology Training</span>
                  </Col>
                  <Col sm={6} className="text-center text-sm-end">
                    <div className="contactUs">
                        To know more, please <a href="tel:+916362359803">Call</a> or <a href="https://wa.me/+916362359803?text=I would like to enroll for a course, share me the details">WhatsApp</a> Us.
                        <br /><span className="phoneNumber"><a href="tel:+916362359803">+91 63623 59803</a></span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <div className="blankCover"></div>
              <div className="responsiveBlankCover d-block d-xs-none d-sm-none"></div>
              </Col>
              </Row>
        </Container>
      </div>
    );
  }
}

export default Header;
