import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import Home from './pages/home'
import Pack from './pages/pack'
import Wolf from './pages/wolf'
import Register from './pages/register'

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact >
          <Home />
        </Route>
        <Route path="/pack" component={Pack} exact />
        <Route path="/pack/:wolf" component={Wolf} exact />
        <Route path="/register" component={Register} exact />
        <Route path="/*" render={() => (<Redirect to="/" />)} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
