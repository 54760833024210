import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import "../assets/css/layout/footer.css";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <Container fluid>
          <Row>
            <Col sm={12}>
              <div className="join-us">
                <a href="/register">
                  <button className="join-us-button btn btn-warning">
                    {" "}
                    Join Us !!!{" "}
                  </button>
                </a>
                <div  className="go-back text-center">
                <a href="/">
                    Go Back Home
                </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Footer;
