import React from "react";

import Header from "../layout/header"
import Footer from "../layout/footer"

import db from '../data/firestore';

import "../assets/css/pack.css";

import { Container, Row, Col } from "react-bootstrap";

class Pack extends React.Component {
constructor(props) {
    super(props)
    this.state = {
      pack: [],
    }
  }
  render() {
  const { pack } = this.state;
  document.title = "Our Pack | Active Learners"
      db.collection("pack").get().then((docObj) => {
        if (docObj.size > 0) {
          if (pack.length !== docObj.docs.length) {
            let wolf_list = []
            docObj.docs.forEach(item=>{
              wolf_list.push({
                "name": item.data().name,
                "dp": item.data().dp,
                "active": item.data().active,
                "completed": item.data().completed,
                "assignments": item.data().assignments,
                "projects": item.data().projects,
                "id": item.id
              });
             })
            this.setState({
              pack: wolf_list
            });
          }
        }
      }).catch((error) => {
        console.log(error);
      })
    return (
      <div className="pack">
      <Header />
        <Container>
          <Row>
            <Col sm={12} className="core-body">
            <Row>
                            {
                              pack.map((item) => {
                                return <Col key={item.id} sm={2} className='item'><a href={"/pack/" + item.id}><div className='dp'><img src={item.dp} width="100%" alt={item.name}></img></div><div className='name'>{item.name}</div></a></Col>
                              })
                            }
                      </Row>
                      <div className="blankCover"></div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default Pack;
