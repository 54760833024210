import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyCcDOwEGEvb8KGz3I_4LYcojJ0lMfDli-E",
    authDomain: "skillhigh-c803b.firebaseapp.com",
    projectId: "skillhigh-c803b",
    storageBucket: "skillhigh-c803b.appspot.com",
    messagingSenderId: "995188719311",
    appId: "1:995188719311:web:bbfb2999174dd1bd7e81ba",
    measurementId: "G-5WBV3PSB15"
};

firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();

export default db;
